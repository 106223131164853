import Papa   from 'papaparse';

export const pascalCase = s => s.replace(/([\w\u00C0-\u017F])([\w\u00C0-\u017F]*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase());

export const formatForCsv = (value, formatter = null, ...args) => {
    if (formatter) return formatter(value, ...args);
    if (value === true) return 'oui';
    if (value === false) return 'non';
    if (Array.isArray(value)) return Papa.unparse(value, {header: false});

    return value;
};

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const randomString = () => Math.random().toString(36).substring(7);

export const textUsername = user => user.prenom + ' ' + user.nom + (user.pseudo ? (' (' + user.pseudo + ')') : '');

export const ucFirst = string => string.charAt(0).toUpperCase() + string.slice(1);

export const lcFirst = string => string.charAt(0).toLowerCase() + string.slice(1);

export const plural = (count, char = 's') => count === 1 ? '' : char;

export default {
    pascalCase,
    formatForCsv,
    isNumeric,
    randomString,
    ucFirst,
    lcFirst,
    plural
};