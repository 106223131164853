const state = {
    display: false
};

const getters = {};

const actions = {
    trigger({commit}) {
        commit('setDisplay', true);
    },
    stop({commit}) {
        commit('setDisplay', false);
    }
};

const mutations = {
    setDisplay(state, display) {
        state.display = display;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};