import Vue                                            from 'vue';
import Router                                         from 'vue-router';
import auth                                           from './util/auth';
import {triggerSlime}                                 from './util/slime';
import {getEasyCodeItem, displayUnseenCollectedItems} from './util/collectableItem';
import {displayLoveMessages}                          from './util/loveMessage';

const NotFound              = () => import('./pages/NotFound');
const Forbidden             = () => import('./pages/Forbidden');
const Homepage              = () => import('./pages/Homepage');
const WebLogin              = () => import('./pages/WebLogin');
const SignUp                = () => import('./pages/SignUp');
const ForgotPassword        = () => import('./pages/ForgotPassword');
const ResetPassword         = () => import('./pages/ResetPassword');
const ClassicTemplate       = () => import('./pages/ClassicTemplate');
const Cgu                   = () => import('./pages/Cgu');
const About                 = () => import('./pages/About');
const Void                  = () => import('./pages/Void');
const ListJeuxLivres        = () => import('./pages/ListJeuxLivres');
const FilEvenements         = () => import('./pages/FilEvenements');
const Evenement             = () => import('./pages/Evenement');
const Calendar              = () => import('./pages/Calendar');
const UserSettings          = () => import('./pages/User/UserSettings');
const ClujiCardWrapper      = () => import('./components/layout/ClujiCardWrapper');
const BContainerWrapper     = () => import('./components/layout/BContainerWrapper');
const ClujiDashboard        = () => import('./pages/User/ClujiDashboard');
const DashboardHome         = () => import('./pages/User/DashboardHome');
const EmpruntsDashboard     = () => import('./pages/User/EmpruntsDashboard');
const NouvelEmprunt         = () => import('./pages/User/NouvelEmprunt');
const NouvelleReservation   = () => import('./pages/User/NouvelleReservation');
const NouvelEvenement       = () => import('./pages/User/NouvelEvenement');
const ReservationsDashboard = () => import('./pages/User/ReservationsDashboard');
const Profile               = () => import('./pages/User/Profile');
const SlimeRanch            = () => import('./pages/User/SlimeRanch');
const Leaderboard           = () => import('./pages/User/Leaderboard');
const Citations             = () => import('./pages/User/Citations');
const AdminUtilisateurs     = () => import('./pages/Bureau/AdminUtilisateurs');
const AdminJdrs             = () => import('./pages/Bureau/AdminJdrs');
const AdminJeux             = () => import('./pages/Bureau/AdminJeux');
const AdminBorrowableTags   = () => import('./pages/Bureau/AdminBorrowableTags');
const AdminLivres           = () => import('./pages/Bureau/AdminLivres');
const AdminReservations     = () => import('./pages/Bureau/AdminReservations');
const AdminEmprunts         = () => import('./pages/Bureau/AdminEmprunts');
const AdminEvenements       = () => import('./pages/Bureau/AdminEvenements');
const AdminPermanences      = () => import('./pages/Bureau/AdminPermanences');
const AdminAssociations     = () => import('./pages/Bureau/AdminAssociations');
const AdminStats            = () => import('./pages/Bureau/AdminStats');
const AdminMailer           = () => import('./pages/Bureau/AdminMailer');
const AdminToyBox           = () => import('./pages/Bureau/AdminToyBox');
const AdminArchives         = () => import('./pages/Bureau/AdminArchives');
const AdminWebcretarium     = () => import('./pages/Bureau/AdminWebcretarium');
const WikiHome              = () => import('./pages/WikiHome');
const WikiArticle           = () => import('./pages/WikiArticle');

// Mobile app components

const MobileLogin      = () => import('./pages/MobileApp/MobileLogin');
const MobileFrame      = () => import('./pages/MobileApp/MobileFrame');
const MobileHome       = () => import('./pages/MobileApp/MobileHome');
const MobileSlimeRanch = () => import('./pages/MobileApp/MobileSlimeRanch');
const MobileAdmin      = () => import('./pages/MobileApp/MobileAdmin');

Vue.use(Router);

const defaultRouterComponent = {
    render: c => c('router-view')
};

const checkPermission = (to, next, condition) => {
    if (!condition()) next(auth.isLoggedIn() ? '/403' : ('/login?redirectTo=' + to.path));
    else next();
};

const checkAnyAsso     = (to, from, next) => checkPermission(to, next, auth.isAnyAsso);
const checkUser        = (to, from, next) => checkPermission(to, next, auth.isUser);
const checkAsso        = (to, from, next) => checkPermission(to, next, auth.isAsso);
const checkCluji       = (to, from, next) => checkPermission(to, next, auth.isCluji);
const checkBureau      = (to, from, next) => checkPermission(to, next, auth.isBureau);
const checkWebcretaire = (to, from, next) => checkPermission(to, next, auth.isWebcretaire);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: WebLogin
        },
        {
            path: '/sign-up',
            name: 'sign_up',
            component: SignUp
        },
        {
            path: '/forgot-password',
            name: 'forgot_password',
            component: ForgotPassword
        },
        {
            path: '/reset-password',
            name: 'reset_password',
            component: ResetPassword
        },
        {
            path: '/logout',
            name: 'logout',
            beforeEnter: (to, from, next) => {
                auth.logout().finally(() => next('/'));
            }
        },
        {
            path: '/void',
            name: 'void',
            component: Void
        },
        {
            path: '',
            name: 'homepage',
            component: Homepage
        },
        {
            path: '/mobile-app',
            name: 'mobile_app',
            component: defaultRouterComponent,
            children: [
                {
                    path: '',
                    name: 'mobile_app_login',
                    component: MobileLogin
                },
                {
                    path: 'main',
                    name: 'mobile_app_main',
                    component: MobileFrame,
                    beforeEnter: checkUser,
                    children: [
                        {
                            path: '',
                            name: 'mobile_app_home',
                            component: MobileHome
                        },
                        {
                            path: 'slimes',
                            name: 'mobile_app_slimes',
                            component: MobileSlimeRanch
                        },
                        {
                            path: 'admin',
                            name: 'mobile_app_admin',
                            component: MobileAdmin
                        }
                    ]
                },
            ]
        },
        {
            path: '/m',
            name: 'user_dashboard',
            component: ClujiDashboard,
            beforeEnter: checkUser,
            children: [
                {
                    path: '',
                    name: 'dashboard_home',
                    component: DashboardHome
                },
                {
                    path: 'profil',
                    name: 'dashboard_profile',
                    component: Profile
                },
                {
                    path: 'slimes',
                    name: 'dashboard_slimes',
                    component: SlimeRanch
                },
                {
                    path: 'emprunts',
                    name: 'dashboard_emprunts',
                    beforeEnter: checkAnyAsso,
                    component: EmpruntsDashboard
                },
                {
                    path: 'nouvel-emprunt',
                    name: 'creation_emprunt',
                    beforeEnter: checkAnyAsso,
                    component: NouvelEmprunt
                },
                {
                    path: 'reservations',
                    name: 'dashboard_reservations',
                    beforeEnter: checkCluji,
                    component: ReservationsDashboard
                },
                {
                    path: 'nouvelle-reservation',
                    name: 'creation_reservation',
                    beforeEnter: checkCluji,
                    component: NouvelleReservation
                },
                {
                    path: 'nouvel-evenement',
                    name: 'creation_evenement',
                    beforeEnter: checkCluji,
                    component: NouvelEvenement
                },
                {
                    path: 'calendrier',
                    name: 'dashboard_calendar',
                    component: Calendar
                },
                {
                    path: 'jeux-et-livres',
                    name: 'dashboard_list_jeux_livres',
                    component: ClujiCardWrapper,
                    meta: {componentInstance: ListJeuxLivres}
                },
                {
                    path: 'evenements',
                    name: 'dashboard_list_evenements',
                    component: FilEvenements
                },
                {
                    path: 'evenement/:eventId/:eventName',
                    name: 'dashboard_evenement',
                    component: Evenement
                },
                {
                    path: 'parametres',
                    name: 'dashboard_settings',
                    component: UserSettings
                },
                {
                    path: 'recompense-gratuite',
                    name: 'easter_egg_easy',
                    beforeEnter: getEasyCodeItem
                },
                {
                    path: 'leaderboard',
                    name: 'dashboard_leaderboard',
                    component: Leaderboard
                },
                {
                    path: 'wiki',
                    name: 'dashboard_wiki',
                    component: WikiHome
                },
                {
                    path: 'wiki/article/:articleId/:articleName',
                    name: 'dashboard_wiki_article',
                    component: WikiArticle
                },
                {
                    path: 'citations',
                    name: 'dashboard_citations',
                    component: Citations
                }
            ]
        },
        {
            path: '/a',
            name: 'admin_dashboard',
            component: ClujiDashboard,
            beforeEnter: checkBureau,
            children: [
                {
                    path: 'utilisateurs',
                    name: 'admin_utilisateurs',
                    component: AdminUtilisateurs
                },
                {
                    path: 'jdrs',
                    name: 'admin_jdrs',
                    component: AdminJdrs
                },
                {
                    path: 'jeux',
                    name: 'admin_jeux',
                    component: AdminJeux
                },
                {
                    path: 'livres',
                    name: 'admin_livres',
                    component: AdminLivres
                },
                {
                    path: 'tags',
                    name: 'admin_borrowable_tags',
                    component: AdminBorrowableTags
                },
                {
                    path: 'emprunts',
                    name: 'admin_emprunts',
                    component: AdminEmprunts
                },
                {
                    path: 'reservations',
                    name: 'admin_reservations',
                    component: AdminReservations
                },
                {
                    path: 'evenements',
                    name: 'admin_evenements',
                    component: AdminEvenements
                },
                {
                    path: 'associations',
                    name: 'admin_associations',
                    component: AdminAssociations
                },
                {
                    path: 'statistiques',
                    name: 'admin_stats',
                    component: AdminStats
                },
                {
                    path: 'permanences',
                    name: 'admin_permanences',
                    component: AdminPermanences
                },
                {
                    path: 'mailing-list',
                    name: 'admin_mailer',
                    component: AdminMailer
                },
                {
                    path: 'boite-a-jouets',
                    name: 'admin_jouets',
                    component: AdminToyBox
                },
                {
                    path: 'archives',
                    name: 'admin_archives',
                    component: AdminArchives
                },
                {
                    path: 'webcretarium',
                    name: 'admin_webcretarium',
                    component: AdminWebcretarium
                }
            ]
        },
        {
            path: '/',
            name: 'classic_pages',
            component: ClassicTemplate,
            children: [
                {
                    path: 'CGU',
                    name: 'cgu',
                    component: Cgu
                },
                {
                    path: 'a-propos',
                    name: 'about',
                    component: About
                },
                {
                    path: 'jeux-et-livres',
                    name: 'list_jeux_livres',
                    component: ListJeuxLivres
                },
                {
                    path: 'evenements',
                    name: 'list_evenements',
                    component: BContainerWrapper,
                    meta: {componentInstance: FilEvenements}
                },
                {
                    path: 'evenement/:eventId/:eventName',
                    name: 'evenement',
                    component: BContainerWrapper,
                    meta: {componentInstance: Evenement}
                },
                {
                    path: 'calendrier',
                    name: 'calendar',
                    component: BContainerWrapper,
                    meta: {componentInstance: Calendar}
                },
                {
                    path: 'wiki',
                    name: 'wiki',
                    component: BContainerWrapper,
                    meta: {componentInstance: WikiHome}
                },
                {
                    path: 'wiki/article/:articleId/:articleName',
                    name: 'wiki_article',
                    component: BContainerWrapper,
                    meta: {componentInstance: WikiArticle}
                }
            ]
        },
        {
            path: '/404',
            name: 'notfound',
            component: NotFound
        },
        {
            path: '/403',
            name: 'forbidden',
            component: Forbidden
        },
        {
            path: '*',
            name: 'notfound_wildcard',
            component: NotFound
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (auth.isLoggedIn()) {
        auth.refreshSession()
            .then(() => next())
            .then(displayLoveMessages)
            .then(() => {
                if (Math.random() < 0.01) triggerSlime(); // 1% chance of triggering a slime
                displayUnseenCollectedItems();
            });
    } else {
        auth.automaticLogin().then(() => next());
    }
});

export default router;