const state = {
    badges: {
        utilisateurs: null,
        emprunts: null,
        local: null,
        evenements: null
    }
};

const getters = {
    getBadgeOrNull: state => type => (!state.badges[type] || Number(state.badges[type]) === 0)
        ? null
        : state.badges[type]
};

const actions = {
    setBadges({commit}, badges) {
        commit('setBadges', badges);
    }
};

const mutations = {
    setBadges(state, badges) {
        state.badges = badges;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};