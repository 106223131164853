// JS dependencies

import Vue                         from 'vue';
import BootstrapVue                from 'bootstrap-vue';
import router                      from './router';
import store                       from './store';
import App                         from './App';
import Toaster                     from 'v-toaster';
import ArgonDashboard              from './argon/plugins/argon-dashboard';
import {sync}                      from 'vuex-router-sync';
import {library}                   from '@fortawesome/fontawesome-svg-core';
import {fas}                       from '@fortawesome/free-solid-svg-icons';
import {far}                       from '@fortawesome/free-regular-svg-icons';
import {fab}                       from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon}           from '@fortawesome/vue-fontawesome';
import VueCookies                  from 'vue-cookies';
import axios                       from 'axios';
import VueAxios                    from 'vue-axios';
import moment                      from 'moment';
import {Settings as LuxonSettings} from 'luxon'; // Luxon is only used internally by datetime pickers

// Configuration

window.shouldAutomaticLogin = true;

moment.locale('fr');
LuxonSettings.defaultLocale = 'fr';

Vue.config.productionTip = false;

sync(store, router);

Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(Toaster, {timeout: 5000});
Vue.use(ArgonDashboard);

library.add(fas);
library.add(far);
library.add(fab);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueCookies);
VueCookies.config('365d');

const app = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
