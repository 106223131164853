import store         from '@/store';
import {requireJson} from './resources';
import {apiPath}     from './http';
import {requireImg}  from './resources';

import axios  from 'axios';
import moment from 'moment';

const ItemCollectionModal = () => import('@/components/modals/collectable_item/ItemCollectionModal');

export const momentDates = data => Array.isArray(data) ? data.map(momentDates) : {
    ...data,
    obtention: moment(data.obtention.date)
};

export const allCollectableItems = requireJson('collectableItems.json');

export const getItem = item => allCollectableItems[item];

export const userHasItem = (item, user = store.state.user.utilisateur) => user.collectableItems.includes(item);

export const triggerItemCollection = itemType => store.dispatch('modal/create', {
    component: ItemCollectionModal,
    props: {itemType: itemType}
});

export const displayUnseenCollectedItems = (user = store.state.user.utilisateur) => {
    let item = user.collectableItems.find(({seen}) => !seen);
    if (item) {
        triggerItemCollection(item.type)
            .then(() => axios.post(apiPath('set_seen_collectable_item', {item: item.id})))
            .then(() => item.seen = true);
    }
};

export const getEasyCodeItem = () => axios.get(apiPath('secret_item_easy'))
    .then(() => window.location.replace('https://www.youtube.com/watch?v=dQw4w9WgXcQ'));

export const requireItemImage = itemType => requireImg('collectableItems/' + itemType + '.png');

export default {
    momentDates,
    allCollectableItems,
    getItem,
    userHasItem,
    triggerItemCollection,
    displayUnseenCollectedItems,
    requireItemImage
};