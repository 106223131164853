import axios from 'axios';
import Vue   from 'vue';

import store          from '@/store';
import {apiPath}      from './http';
import {isLoggedIn}   from './auth';
import {textUsername} from './user';

export const displayDuration = 20000; // in milliseconds

export const clearMessages = () => store.dispatch('loveMessage/hide') // Hide current message
    .then(() => store.dispatch('user/clearLoveMessages')) // Delete messages form the store's user
    .then(() => axios.delete(apiPath('clear_love_messages'))) // Push deletion of messages on server
    .catch(() => console.error('Can\'t delete love messages, I guess you\'ll have to see them again, probably'));

export const displayLoveMessage = loveMessage => {
    let displayMessage = loveMessage.fromUser ? 'Cœurs de ' + textUsername(loveMessage.fromUser) : '';
    if (displayMessage.length && loveMessage.message)
        displayMessage += ' : ';

    if (loveMessage.message)
        displayMessage += loveMessage.message;

    store.dispatch('loveMessage/display', loveMessage)
        .then(() => {
            if (displayMessage.length)
                Vue.toaster.info(displayMessage);
        });
};

export const displayLoveMessages = () => new Promise(resolve => {
    // Stop here if the user isn't logged in, or they don't have any new message, or one is already being displayed
    if (!isLoggedIn()
        || !store.state.user.utilisateur.loveMessages.length
        || store.state.loveMessage.loveMessage != null) {
        resolve();
        return;
    }

    let scheduleTime = 0;

    // Schedule messages to display one at a time
    for (let message of store.state.user.utilisateur.loveMessages) {
        setTimeout(() => displayLoveMessage(message), scheduleTime);
        scheduleTime += displayDuration;
    }

    // Finally clear them once we're done, client and server side
    setTimeout(() => {
        clearMessages();
        resolve();
    }, scheduleTime);
});


export default {displayDuration, clearMessages, displayLoveMessage, displayLoveMessages};