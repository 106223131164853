<template>
    <li class="nav-item" :style="{ '--link-color' : link_color, '--active-background': link_color + '20'}">
        <router-link :to="link.path" :class="link_class" :target="link.target">
            <template>
                <font-awesome-icon :icon="link.icon" class="mr-2" style="color: var(--link-color)"/>
                <span class="nav-link-text">
                    {{ link.name }}
                    <b-badge class="ml-1" v-if="link.badge">{{ link.badge }}</b-badge>
                </span>
            </template>
        </router-link>
    </li>
</template>

<script>
    export default {
        name: 'sidebar-item',
        props: {
            link: {
                type: Object,
                default: () => {
                    return {
                        name: '',
                        path: '',
                        children: []
                    };
                },
                description:
                    'Sidebar link. Can contain name, path, icon and other attributes. See examples for more info'
            }
        },
        data() {
            return {
                children: [],
                collapsed: true
            };
        },
        computed: {
            link_color() {
                return this.link.color || '#7f7f7f';
            },
            link_class() {
                // On utilise le store et pas this.$route, sinon le composant ne se met pas à jour quand on change de page
                return this.$store.state.route.name === this.link.path.name ? ['nav-link', 'active'] : ['nav-link'];
            }
        }
    };
</script>

<style scoped>
    .nav-link.active {
        background-color: var(--active-background);
    }

    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active::before {
        top: 0;
        bottom: 0;
        border-left: 2px solid var(--link-color);
    }

    .badge {
        border: 1px solid #d6ebf9;
    }
</style>
