const state = {
    display: false,
    component: null,
    alert: false,
    props: []
};

const getters = {};

const actions = {
    create({commit}, modal) {
        commit('create', modal);
    },
    destroy({commit}) {
        commit('destroy');
    },
    hide({commit}) {
        commit('hide');
    },
    triggerAlert({commit}) {
        commit('alert', true)
    },
    stopAlert({commit}) {
        commit('alert', false)
    },
};

const mutations = {
    create(state, modal) {
        state.display   = true;
        state.component = modal.component;
        if (modal.props) {
            state.props = modal.props;
        }
    },
    destroy(state) {
        state.component = null;
        state.props     = [];
    },
    hide(state) {
        state.display = false;
    },
    alert(state, alert) {
        state.alert = alert;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};