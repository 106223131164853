const state = {
    type: null
};

const getters = {};

const actions = {
    trigger({commit}, type) {
        commit('setType', type);
    },
    stop({commit}) {
        commit('setType', null);
    }
};

const mutations = {
    setType(state, type) {
        state.type = type;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};