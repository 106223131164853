import Vue                 from 'vue';
import Vuex                from 'vuex';
import createMultiTabState from 'vuex-multi-tab-state';
import modal               from './store/modal';
import user                from './store/user';
import fireworks           from './store/fireworks';
import slime               from './store/slime';
import badge               from './store/badge';
import loveMessage         from './store/loveMessage';
import mobile              from './store/mobile';

Vue.use(Vuex);

export default new Vuex.Store({
    // This might be an overly complex way of checking localStorage support but who cares
    plugins: typeof Storage !== 'undefined' && 'localStorage' in window && window.localStorage != null
    ? [
        createMultiTabState({
            statesPaths: ["user", "badge", "loveMessage", "mobile"],
        }),
    ] 
    : [], // Mobile app doesn't have local storage (but doesn't need tab synchronisation anyways)
    modules: {
        modal,
        user,
        fireworks,
        slime,
        badge,
        loveMessage,
        mobile
    }
});
