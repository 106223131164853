import * as firebase from "firebase/app";
import "firebase/messaging";

import {isBureau} from "./auth";

import axios     from 'axios';
import {apiPath} from "./http";

let firebaseApp;

const firebaseConfig = {
    apiKey: "AIzaSyADXUdw0dtPS1Sf2pbPoEGDJvyC9qKPttM",
    authDomain: "cluji-65d62.firebaseapp.com",
    databaseURL: "https://cluji-65d62.firebaseio.com",
    projectId: "cluji-65d62",
    storageBucket: "cluji-65d62.appspot.com",
    messagingSenderId: "38828363065",
    appId: "1:38828363065:web:044476ad3e725fa3782ce8"
};

const onMessage = payload => {
    let data = payload.notification;
    let link = payload.fcmOptions.link;

    const notificationTitle   = 'Cluji';
    const notificationOptions = {
        body: data.body,
        icon: '/img/favicon.png',
    };

    let notification = new Notification(notificationTitle, notificationOptions);

    notification.onclick = event => {
        event.preventDefault();
        if (link) window.location.href = link;
    };
};

export const initServiceWorker = () => {
    firebaseApp = firebase.initializeApp(firebaseConfig);
};

export const initNotifications = () => {
    if (isBureau() && 'Notification' in window) { // Right now normal users don't need notifications
        const messaging = firebaseApp.messaging();
        messaging.onMessage(onMessage);

        Notification
            .requestPermission()
            .then(() => messaging.getToken())
            .then(token => token
                ? axios.post(apiPath('add_webpush_device'), {token: token})
                : Promise.resolve())
            .catch(() => console.error('Error registering the webpush token'));
    }
};

export const DEVICE_TYPES = {
    WEB: 1,
    MOBILE: 2
};

export default {initServiceWorker, initNotifications, DEVICE_TYPES};