import {requireJson, requireImg} from './resources';
import store                     from '@/store';
import axios                     from 'axios';
import {apiPath}                 from "./http";
import moment                    from "moment";

export const momentDates = data => Array.isArray(data) ? data.map(momentDates) : {
    ...data,
    dateCapture: moment(data.dateCapture.date),
    dateFaim: moment(data.dateFaim.date)
};

export const allSlimes = () => requireJson('slimes.json');

export const triggerSlime = () => axios.get(apiPath('get_random_slime'))
    .then(response => store.dispatch('slime/trigger', response.data.type));

export const hasSlime = (user = store.state.user.utilisateur) => user.slimes && user.slimes.length;

export const requireSlimeImgSrc = slime => requireImg('slimes/' + slime.type + (slime.forme ? '_s' : '') + '.png');

export const requireSlimeTypeImgSrc = type => requireImg('slimes/' + type + '.png');

export const getType = type => allSlimes()[type];

export const getSlimeNom = slime => slime.nom || getType(slime.type).nom;

export const isHungry = slime => slime.dateFaim < moment();

export default {
    allSlimes,
    triggerSlime,
    hasSlime,
    requireSlimeImgSrc,
    requireSlimeTypeImgSrc,
    getType,
    getSlimeNom,
    isHungry
};