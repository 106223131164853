import Routing from "@/../../public/bundles/fosjsrouting/js/router.min";
import router  from '@/router';

const routes = require('@/fos_js_routes.json');

Routing.setRoutingData(routes);

export const apiPath = (route, params = {}) => Routing.generate(route, params);

export const frontPath = (route, params = {}) => router.resolve({name: route, params}).href;

export const downloadResponse = response => {
    const url  = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href  = url;
    link.setAttribute('download', response.headers["content-disposition"].split('filename=')[1]);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export default {apiPath, frontPath, downloadResponse};