const state = {utilisateur: null, token: null};

const getters = {};

const actions = {
    login({commit}, token) {
        commit('setToken', token);
    },
    refreshUser({commit}, user) {
        commit('setUser', user);
    },
    logout({commit}) {
        commit('setUser', null);
        commit('setToken', null);
    },
    clearLoveMessages({commit}) {
        commit('clearLoveMessages');
    }
};

const mutations = {
    setUser(state, user) {
        state.utilisateur = user;
    },
    setToken(state, token) {
        state.token = token;
    },
    clearLoveMessages(state) {
        if (state.utilisateur) state.utilisateur.loveMessages = [];
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};