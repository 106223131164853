const state = {
    loveMessage: null
};

const getters = {};

const actions = {
    display({commit}, loveMessage) {
        commit('setLoveMessage', loveMessage);
    },
    hide({commit}) {
        commit('setLoveMessage', null);
    }
};

const mutations = {
    setLoveMessage(state, loveMessage) {
        state.loveMessage = loveMessage
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};