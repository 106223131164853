<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
        <div class="container-fluid">
            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>
            <router-link class="navbar-brand" to="/">
                <img :src="logo" class="navbar-brand-img" alt="Cluji">
            </router-link>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none">
                    <base-dropdown class="nav-item" position="right">
                        <a slot="title" class="nav-link" href="#" role="button">
                            <i class="ni ni-single-02"></i>
                        </a>

                        <dashboard-navbar-links/>
                    </base-dropdown>
                </ul>
            </slot>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <b-img :src="logo"/>
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links">
                    </slot>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
    const NavbarToggleButton   = () => import('@/argon/components/NavbarToggleButton');
    const DashboardNavbarLinks = () => import('@/components/layout/DashboardNavbarLinks');

    export default {
        name: 'sidebar',
        components: {
            DashboardNavbarLinks,
            NavbarToggleButton
        },
        props: {
            logo: {
                type: String,
                default: '/img/logo.png',
                description: 'Sidebar app logo'
            },
            autoClose: {
                type: Boolean,
                default: true,
                description:
                    'Whether sidebar should autoclose on mobile when clicking an item'
            }
        },
        provide() {
            return {
                autoClose: this.autoClose
            };
        },
        methods: {
            closeSidebar() {
                this.$sidebar.displaySidebar(false)
            },
            showSidebar() {
                this.$sidebar.displaySidebar(true)
            }
        },
        beforeDestroy() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.showSidebar = false;
            }
        }
    };
</script>

<style>
    .navbar-vertical.navbar-expand-md .navbar-brand-img {
        max-height: 5rem;
    }

    .navbar-vertical.navbar-expand-md .navbar-brand {
        padding-bottom: 0;
    }

    .navbar-brand-img {
        height: 100%;
        filter: drop-shadow(0 0 0.1rem black);
    }

    nav.navbar-vertical.navbar-expand-md#sidenav-main .navbar-nav a.nav-link {
        padding-top: 0.55rem;
        padding-bottom: 0.55rem;
    }

    #sidenav-main {
        z-index: 1;  /* Because seasonal falling things have z-index 0 */
    }
</style>