const state = {
    token: null
};

const getters = {};

const actions = {
    setToken({commit}, token) {
        commit('setToken', token);
    }
};

const mutations = {
    setToken(state, token) {
        state.token = token;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};