import store               from '@/store';
import http                from './http';
import user                from './user';
import {initNotifications} from './firebase';

import axios from 'axios';
import Vue   from 'vue';

export const setupAuthorizationHeader = () =>
    axios.defaults.headers.common = store.state.user.token
        ? {"Authorization": "Bearer " + store.state.user.token}
        : {};

// We use only "finally"s to go to the next step even if the user was already partially logged out for some reason
export const logout = () => axios.post(http.apiPath('delete_remember_me_token'))
    .catch(() => console.warn('Ignoring error while deleting "remember_me" token'))
    .finally(() => axios.post(http.apiPath('delete_webpush_devices')))
    .catch(() => console.warn('Ignoring error while deleting webpush devices'))
    .finally(() => {
        window.shouldAutomaticLogin = null;
        return store.dispatch('user/logout');
    })
    .catch(() => console.warn('Ignoring error while logging out in the store'))
    .finally(setupAuthorizationHeader);

export const refreshSession = () => {
    setupAuthorizationHeader();

    if (store.state.user.token) {
        return axios.get(http.apiPath('user_infos'))
            .then(response => {
                if (response.data.code && [401, 403].contains(response.data.code)) return logout();
                else return store.dispatch('user/refreshUser', user.momentDates(response.data));
            })
            .catch(logout);
    }

    return Promise.resolve();
};

export const automaticLogin = () => window.shouldAutomaticLogin
    ? axios.get(http.apiPath('get_jwt_token'))
        .then(response => store.dispatch('user/login', response.data.token))
        .then(refreshSession)
        .catch(() => window.shouldAutomaticLogin = false) // Disable automatic login (we were probably logged out from another tab, this is normal)
        .then(initNotifications)
    : Promise.resolve();

export const isCurrentlyLoggedIn         = user => isLoggedIn() && user.id === store.state.user.utilisateur.id;
export const isCurrentlyLoggedInOrBureau = user => isBureau() || isCurrentlyLoggedIn(user);

// All these methods are just wrappers for the user util, which use the current logged in user form the store
export const isLoggedIn    = () => user.isValidUser(store.state.user.utilisateur);
export const isUser        = () => user.isUser(store.state.user.utilisateur);
export const isAnyAsso     = () => user.isAnyAsso(store.state.user.utilisateur);
export const isAsso        = () => user.isAsso(store.state.user.utilisateur);
export const isCluji       = () => user.isCluji(store.state.user.utilisateur);
export const isBureau      = () => user.isBureau(store.state.user.utilisateur);
export const isWebcretaire = () => user.isWebcretaire(store.state.user.utilisateur);
export const isAncien      = () => user.isAncien(store.state.user.utilisateur);
export const isGranted     = role => user.isGranted(store.state.user.utilisateur, role);

export default {
    setupAuthorizationHeader,
    logout,
    refreshSession,
    automaticLogin,
    isCurrentlyLoggedIn,
    isCurrentlyLoggedInOrBureau,
    isLoggedIn,
    isUser,
    isAnyAsso,
    isAsso,
    isCluji,
    isBureau,
    isWebcretaire,
    isAncien
};