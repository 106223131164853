<template>
    <div>
        <b-alert v-if="showCookieBanner" show dismissible variant="info"
                 @dismissed="setCookieConsent" class="cookieBanner">
            Ce site n'utilise aucun cookie de mesure d'audience, télémétrie ou pistage, seulement ceux nécessaires à son
            fonctionnement. En utilisant nos services, vous acceptez l'utilisation de ces cookies.
        </b-alert>

        <router-view/>
        <fireworks v-if="fireworks.display"/>
        <moving-slime v-if="slime.type" :type="slime.type"/>
        <div v-if="modal.component" :is="modal.component" v-bind="modal.props" ref="modalElement"></div>
        <!-- Alert is a separate thing (not a standard modal), because sometimes
        we need to be able to trigger a modal and a loading at the same time -->
        <modal-alert v-if="modal.alert" ref="modalAlert" type="loading" message="Veuillez patienter ..."/>
        <floating-hearts :class="heartsClass" v-if="displayHearts"/>
    </div>
</template>

<script>
    import {apiPath}                  from '@/util/http';
    import {initServiceWorker}        from '@/util/firebase';
    import {setupAuthorizationHeader} from '@/util/auth';

    import {mapState} from 'vuex';

    const FloatingHearts = () => import('@/components/FloatingHearts');
    const Fireworks      = () => import('@/components/Fireworks');
    const MovingSlime    = () => import('@/components/slime/MovingSlime');
    const ModalAlert     = () => import('@/components/modals/ModalAlert');

    export default {
        name: 'app',
        components: {FloatingHearts, MovingSlime, ModalAlert, Fireworks},
        data: () => ({
            renewJwtInterval: null,
            heartsClass: [],
            displayHearts: false
        }),
        methods: {
            setCookieConsent() {
                this.$cookies.set('cookie_consent', 1);
            },
            /**
             * Since JWT tokens TTL do not update automatically, we have to periodically ask for a new one
             * to stay connected (cf https://github.com/lexik/LexikJWTAuthenticationBundle/issues/65 )
             */
            renewJwtToken() {
                this.renewJwtInterval = setInterval(
                    () => {
                        if (this.user.token) {
                            this.axios.get(apiPath('renew_jwt_token'))
                                .then(response => this.$store.dispatch('user/login', response.data.token))
                                .then(setupAuthorizationHeader)
                                .catch(() => console.error('Error while renewing JWT auth token'));
                        }
                    },
                    5 * 60 * 1000 // 5 minutes (this should be more than enough since the token is valid for 1 hour)
                );
            },
            modalWatcher(type, newVal) {
                if (!newVal) {
                    setTimeout(
                        // Because of async loading of components, sometimes we have concurrency issues, and the modal
                        // never closes although it should, so if we don't have a component yet we wait 1s before
                        // trying to close the modal, which fixes the problem
                        () => {
                            if (this.$refs[type]) {
                                this.$refs[type].hideModal();
                            }
                        },
                        this.$refs[type] ? 0 : 1000
                    );
                }
            }
        },
        computed: {
            ...mapState({
                user: 'user',
                fireworks: 'fireworks',
                slime: 'slime',
                modal: 'modal',
                loveMessage: 'loveMessage'
            }),
            showCookieBanner() {
                return !this.$cookies.get('cookie_consent');
            }
        },
        watch: {
            'modal.display'(newVal) { // When the display property of the state changes, hide the modal
                this.modalWatcher('modalElement', newVal);
            },
            'modal.alert'(newVal) { // When the display property of the state changes, hide the modal
                this.modalWatcher('modalAlert', newVal);
            },
            'loveMessage.loveMessage'(newVal) {
                if (newVal) {
                    this.displayHearts = true;
                    this.heartsClass   = ['opacity-10'];
                } else {
                    this.heartsClass = ['fadeHearts'];
                    // Wait for CSS transition before destroying component
                    setTimeout(() => this.displayHearts = false, 2000);
                }
            }
        },
        beforeDestroy() {
            clearInterval(this.renewJwtInterval);
        },
        created() {
            // Fireworks should never be initially active (this prevent fireworks from continuing after page refresh)
            if (this.fireworks.display) this.$store.dispatch('fireworks/stop');
            // Same for slimes
            if (this.slime.type) this.$store.dispatch('slime/stop');

            initServiceWorker();
            this.renewJwtToken();
        }
    };
</script>

<style lang="scss">
    /* Animate */
    @import '~animate.css/animate.min.css';
    /* Import Bootstrap Vue Styles */
    @import '~bootstrap/scss/bootstrap';
    @import '~bootstrap-vue/src/index.scss';
    /* Vue multiselect style */
    @import '~vue-multiselect/dist/vue-multiselect.min.css';
    /* Vue toaster */
    @import '~v-toaster/dist/v-toaster.css';
    /* Datetime picker */
    @import '~vue-datetime/dist/vue-datetime.css';
    /* Custom */
    @import './main.scss';
    @import './ckcontent.css';
</style>

<style scoped>
    .cookieBanner {
        position: fixed;
        bottom: 0;
        left: 50%; /* left & transform = hack to center fixed elements */
        transform: translateX(-50%);
        width: 90vw;
        z-index: 1000;
        background-color: var(--warning);
        border: none;
    }

    .fadeHearts {
        transition: all 2s;
        opacity: 0;
    }
</style>