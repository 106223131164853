import BaseDropdown from "@/argon/components/BaseDropdown";
import BaseNav from "@/argon/components/BaseNav";
import BaseHeader from "@/argon/components/BaseHeader";

export default {
  install(Vue) {
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseHeader.name, BaseHeader);
  }
};
