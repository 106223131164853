<template>
    <div class="header" :class="{[`bg-${type}`]: type}">
        <div class="container-fluid">
            <div class="header-body">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
    const SeasonalBackground = () => import('@/components/SeasonalBackground');

    export default {
        name: 'base-header',
        components: {SeasonalBackground},
        props: {
            type: {
                type: String,
                default: 'primary',
                description: 'Header background type'
            }
        }
    }
</script>

<style scoped>
    div.header.bg-gradient-success {
        background: url("/img/background.jpg") no-repeat center !important;
        background-size: cover !important;
        z-index: -1;  /* Because seasonal falling things have z-index 0 */
    }
</style>
