import moment from 'moment';

import {pascalCase} from './text';

export const momentDates = data => Array.isArray(data) ? data.map(momentDates) : {
    ...data,
    dateInscription: moment(data.dateInscription.date)
};

export const age = user => moment.duration(moment().diff(user.dateInscription));

export const textUsername = user => pascalCase(user.prenom + ' ' + user.nom) +
    (user.pseudo ? ' (' + user.pseudo + ')' : '');

export const roleInfos = user => {
    if (isWebcretaire(user))
        return {name: 'Webcrétaire', icon: 'code-branch'};

    if (isBureau(user))
        return {name: 'Bureau du Cluji', icon: 'crown'};

    if (isCluji(user))
        return {name: 'Clujiste', icon: 'dragon'};

    if (isAsso(user))
        return {name: 'Association non-Cluji', icon: 'globe-europe'};

    if (isAncien(user))
        return {name: 'Ancien Clujiste', icon: ['far', 'hourglass']};

    if (isUser(user))
        return {name: 'Invité', icon: 'child'};

    return null;
};

export const isValidUser = user => user != null && Array.isArray(user.roles);

// In all these functions, the || implement the same role hierarchy as in security.yaml
export const isUser        = user => isValidUser(user) && user.roles.includes('ROLE_USER') || isAnyAsso(user) || isAncien(user);
export const isAnyAsso     = user => isValidUser(user) && user.roles.includes('ROLE_ANY_ASSO') || isAsso(user) || isCluji(user);
export const isAsso        = user => isValidUser(user) && user.roles.includes('ROLE_ASSO');
export const isCluji       = user => isValidUser(user) && user.roles.includes('ROLE_CLUJI') || isBureau(user);
export const isBureau      = user => isValidUser(user) && user.roles.includes('ROLE_BUREAU') || isWebcretaire(user);
export const isWebcretaire = user => isValidUser(user) && user.roles.includes('ROLE_WEBCRETAIRE');
export const isAncien      = user => isValidUser(user) && user.roles.includes('ROLE_ANCIEN');

const VALIDATION_FUNCTION_FOR_ROLE = {
    ROLE_USER: isUser,
    ROLE_ANY_ASSO: isAnyAsso,
    ROLE_ASSO: isAsso,
    ROLE_CLUJI: isCluji,
    ROLE_BUREAU: isBureau,
    ROLE_WEBCRETAIRE: isWebcretaire,
    ROLE_ANCIEN: isAncien,
};

export const isGranted = (user, role) => VALIDATION_FUNCTION_FOR_ROLE.hasOwnProperty(role) &&
    VALIDATION_FUNCTION_FOR_ROLE[role](user);

export const isAncienAndWantsRevalidation = user => isAncien(user) && user.ancienWantsRevalidation;

export default {
    age,
    textUsername,
    roleInfos,
    momentDates,
    isValidUser,
    isUser,
    isAnyAsso,
    isAsso,
    isCluji,
    isBureau,
    isWebcretaire,
    isAncien,
    isGranted,
    isAncienAndWantsRevalidation
};